<!-- eslint-disable no-unused-vars -->
<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('pui9.delete.title')"
			:messageText="$t('pui9.delete.confirm')"
			:modelName="modelName"
			:dialogName="dialogNameDeleteNotificacion"
			:onOk="onOkDeleteNotificacion"
		>
			<template slot="message">
				<v-row dense>
					<!-- IDNOTIF -->
					<v-col cols="12">
						<pui-field :label="$t('pui9.delete.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<!--<pui-modal-dialog
			v-if="showDialogDeleteNotificacion"
			:modelName="modelName"
			:cancelText="$t('pui9.cancel')"
			:okText="$t('pui9.accept')"
			:titleText="$t('pui9.delete.title')"
			:messageText="$t('pui9.delete.confirm')"
			:dialogName="dialogNameDeleteNotificacion"
			:onOk="onOkDeleteNotificacion"
		></pui-modal-dialog>-->
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.aceptar.title')"
			:messageText="$t('notificacion.modal.aceptar.confirm')"
			:modelName="modelName"
			:componentHeaderName="'notificacion-form-header'"
			:dialogName="dialogNameAceptarNotificacion"
			:onShow="onShowAceptarNotificacion"
			:onOk="onOkAceptarNotificacion"
			:disableCancel="erroresvalidacion"
			:okText="okTextAceptarNotificacion"
			:widthDialog="widthDialogAceptarNotificacion"
			:overflow="true"
		>
			<template slot="message">
				<v-row dense v-if="!erroresvalidacion">
					<v-col cols="12">
						<pui-field :label="$t('notificacion.modal.aceptar.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
				<v-row dense v-if="erroresvalidacion">
					<!-- ERORES -->
					<v-col cols="12">
						<pui-text-area
							:id="`errores-notificacion.modal.aceptar`"
							:label="$t('notificacion.modal.aceptar.errores')"
							v-model="modelerrores.texto"
							toplabel
							disabled
							maxlength="350"
							rows="6"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.cancelar.title')"
			:messageText="$t('notificacion.modal.cancelar.confirm')"
			:modelName="modelName"
			:componentHeaderName="'notificacion-form-header'"
			:dialogName="dialogNameCancelarNotificacion"
			:onOk="onOkCancelarNotificacion"
			:disableCancel="erroresvalidacion"
			:okText="okTextCancelarNotificacion"
			:overflow="true"
			widthDialog="950"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`motivo-notificacion.modal.cancelar`"
							:label="$t('notificacion.modal.motivocancel')"
							v-model="data.modalData.razoreemcan"
							toplabel
							maxlength="350"
							required
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.cancelar.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.reemplazar.title')"
			:messageText="$t('notificacion.modal.reemplazar.confirm')"
			:modelName="modelName"
			:componentHeaderName="'notificacion-form-header'"
			:dialogName="dialogNameReemplazarNotificacion"
			:onOk="onOkReemplazarNotificacion"
			:okText="okTextReemplazarNotificacion"
			:overflow="true"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`motivo-notificacion.modal.reeplazo`"
							:label="$t('notificacion.modal.motivoreemplazo')"
							v-model="data.modalData.razoreemcan"
							toplabel
							maxlength="350"
							required
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.reemplazar.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<!-- REGISTRAR ENTRADA MERCANCÍA -->
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.ctrlentreal.title')"
			:messageText="$t('notificacion.modal.ctrlentreal.confirm')"
			:modelName="modelName"
			:componentHeaderName="'notificacion-form-header'"
			:dialogName="dialogNameCrtlEntNotificacion"
			:onOk="onOkCrtlEntNotificacion"
			:okText="okTextCrtlEntNotificacion"
			:onShow="onShowCrtlEntNotificacion"
			v-model="internalModel"
			:overflow="true"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="6">
						<pui-date-field
							:id="`fecentreal-notificacion`"
							:label="$t('notificacion.fecentreal')"
							v-model="data.modalData.fecentreal"
							toplabel
							time
							required
							:key="'fecentreal-notificacion_' + refreshkey"
						></pui-date-field>
					</v-col>
					<v-col cols="6" class="mt-8">
						<pui-field style="color: rgb(255, 0, 0)" :label="textoerrorfechasreales" value=""></pui-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.ctrlentreal.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<!-- REGISTRAR ENTRADA MERCANCÍA MASIVO -->
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.ctrlentreal.title')"
			:messageText="$t('notificacion.modal.ctrlentreal.confirm')"
			:modelName="modelName"
			:dialogName="dialogNameCrtlEntNotificacionMassive"
			:onOk="onOkCrtlEntNotificacionMassive"
			:okText="okTextCrtlEntNotificacion"
			v-model="internalModel"
			:overflow="true"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`fecentreal-notificacion`"
							:label="$t('notificacion.fecentreal')"
							v-model="data.modalData.fecentreal"
							toplabel
							time
							required
							:key="'fecentreal-notificacion_' + refreshkey"
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.ctrlentreal.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<!-- REGISTRAR SALIDA MERCANCÍA -->
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.ctrlsalreal.title')"
			:messageText="$t('notificacion.modal.ctrlsalreal.confirm')"
			:modelName="modelName"
			:componentHeaderName="'notificacion-form-header'"
			:dialogName="dialogNameCrtlSalNotificacion"
			:onOk="onOkCrtlSalNotificacion"
			:okText="okTextCrtlSalNotificacion"
			:onShow="onShowCrtlSalNotificacion"
			v-model="internalModel"
			:overflow="true"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="6">
						<pui-date-field
							:id="`fecsalreal-notificacion`"
							:label="$t('notificacion.fecsalreal')"
							v-model="data.modalData.fecsalreal"
							toplabel
							time
							required
							:key="'fecsalreal-notificacion_' + refreshkey"
						></pui-date-field>
					</v-col>
					<v-col cols="6" class="mt-8">
						<pui-field style="color: rgb(255, 0, 0)" :label="textoerrorfechasreales" value=""></pui-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.ctrlsalreal.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<!-- REGISTRAR SALIDA MERCANCÍA MASIVO -->
		<pui-modal-dialog-form
			:titleText="$t('notificacion.modal.ctrlsalreal.title')"
			:messageText="$t('notificacion.modal.ctrlsalreal.confirm')"
			:modelName="modelName"
			:dialogName="dialogNameCrtlSalNotificacionMassive"
			:onOk="onOkCrtlSalNotificacionMassive"
			:okText="okTextCrtlSalNotificacion"
			v-model="internalModel"
			:overflow="true"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`fecsalreal-notificacion`"
							:label="$t('notificacion.fecsalreal')"
							v-model="data.modalData.fecsalreal"
							toplabel
							time
							required
							:key="'fecsalreal-notificacion_' + refreshkey"
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-field style="color: rgb(22, 107, 242)" :label="$t('notificacion.modal.ctrlsalreal.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<!-- FIRMAR AUTORIZACION -->
		<notificacion-pdf-dialog
			:titleText="$t('action.notificacion.pdfAutorizacion')"
			:messageText="$t('pui9.delete.confirm')"
			:modelName="modelName"
			:dialogName="dialogNamePdfNotificacion"
			:okText="okTextFirmarPdf"
			:cancelText="$t('pui9.cancel')"
			:extraButton="verPdf"
			:onOk="firmarDocumento"
			:onExtraButton="verDocumento"
			:showDialogProp="showDialog"
			:onShow="onShowPdfAutorizacion"
			widthDialog="600"
		>
			<template slot="message">
				<v-overlay :value="loading" :absolute="true">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
				<v-row dense>
					<!-- IDNOTIF -->
					<v-col cols="12">
						<pui-field :label="$t('action.notificacion.pdfAutorizacion.message')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</notificacion-pdf-dialog>
	</div>
</template>

<script>
import notificacionPdfDialog from './NotificacionPdfDialog';
import AutoScript from '../../assets/autoscript';

export default {
	name: 'notificacion-modals',
	components: {
		notificacionPdfDialog
	},
	data() {
		return {
			notificacionSampleModal: 'notificacionSampleAction',
			dialogNameDeleteNotificacion: 'deleteNotificacion',
			dialogNameAceptarNotificacion: 'aceptarNotificacion',
			dialogNameCancelarNotificacion: 'cancelarNotificacion',
			dialogNameReemplazarNotificacion: 'reemplazarNotificacion',
			dialogNameCrtlEntNotificacion: 'crtlEntNotificacion',
			dialogNameCrtlEntNotificacionMassive: 'crtlEntNotificacionMassive',
			dialogNameCrtlSalNotificacion: 'crtlSalNotificacion',
			dialogNameCrtlSalNotificacionMassive: 'crtlSalNotificacionMassive',
			dialogNamePdfNotificacion: 'firmarPdfNotificacion',
			showDialogDeleteNotificacion: false,
			erroresvalidacion: false,
			modelerrores: {},
			abrirautomaticamenteMtoRemplazada: true,
			refreshkey: 0,
			textoerrorfechasreales: '',
			internalModel: {
				fecentreal: null,
				fecsalreal: null
			},
			dto: '',
			base64String: '',
			pk: null,
			showDialog: true,
			loading: false
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		/*this.$puiEvents.$on(`pui-modalDialog-${this.dialogNameDeleteNotificacion}-${this.modelName}-show`, (data) => {
			this.showDialogDeleteNotificacion = true;
			this.modalData = data;
		});*/
		/*this.$puiEvents.$on(`pui-modalDialog-${this.dialogNameDeleteNotificacion}-${this.modelName}-cancel`, () => {
			this.showDialogDeleteNotificacion = false;
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
		});
		this.$puiEvents.$on(`pui-modalDialog-${this.dialogNameDeleteNotificacion}-${this.modelName}-ok`, () => {
			this.showDialogDeleteNotificacion = false;
			this.onOkDeleteNotificacion(this.modalData);
		});*/
	},
	methods: {
		onOkDeleteNotificacion(modalData) {
			const urlController = this.$store.getters.getModelByName('notificacion').url.delete;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.deleteNotificacion`);

			return new Promise((resolve) => {
				this.$puiRequests.deleteRequest(
					urlController,
					null,
					(response) => {
						console.log('response: ' + response);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						var message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(true);
					},
					{ idnotif: modalData.idnotif }
				);
			});
		},
		onShowAceptarNotificacion() {
			this.erroresvalidacion = false;
			this.modelerrores = {};
		},
		onOkAceptarNotificacion(modalData) {
			//si muestro los errores el boton ok se debe comportad como un cancel y no hacer nada
			if (this.erroresvalidacion) {
				return new Promise((resolve) => {
					resolve(true);
				});
			}

			const body = {
				idnotif: modalData.idnotif
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.aceptar;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.aceptarNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						var resultCode = response.data.resultCode;
						if (resultCode === 0) {
							resolve(true);
							this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						} else {
							this.erroresvalidacion = true;
							var texto = '';
							var nomregla = '';
							response.data.errores.forEach((element) => {
								nomregla = this.$appUtils.isEmpty(element.nombreregla) ? '' : ' - ' + element.nombreregla;
								texto += '\u00B7 ' + '(' + element.codrechazo + nomregla + ') ' + element.elemento + ': ' + element.texto + '\n';
							});
							this.$set(this.modelerrores, 'texto', texto);

							this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
							resolve(false);
						}
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkCancelarNotificacion(modalData) {
			const body = {
				idnotif: modalData.idnotif,
				razoreemcan: modalData.razoreemcan
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.cancelar;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.cancelarNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						var data = response.data;
						console.log(data);
						resolve(true);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkReemplazarNotificacion(modalData) {
			const body = {
				idnotif: modalData.idnotif,
				razoreemcan: modalData.razoreemcan
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.reemplazar;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.reemplazarNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						if (this.abrirautomaticamenteMtoRemplazada) {
							const pk = { idnotif: response.data.idnotif };
							const pkB64 = this.$puiUtils.utf8ToB64(JSON.stringify(pk));
							const params = {
								router: this.$router,
								url: `/${this.modelName}/update/${pkB64}/`,
								params: null,
								query: {},
								replace: true
							};
							this.$store.dispatch('puiRouterGoToUrl', params);
						} else {
							resolve(true);
							this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						}
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkCrtlEntNotificacion(modalData) {
			const moment = require('moment');
			if (modalData.fecsalreal != null) {
				var mse = moment(modalData.fecentreal, 'YYYY-MM/DDTHH:mm:ss.000Z');
				var mss = moment(modalData.fecsalreal, 'YYYY-MM/DDTHH:mm:ss.000Z');
				if (mss.isBefore(mse)) {
					this.textoerrorfechasreales = this.$t(`action.notificacion.crtlEntNotificacion.error`);
					return;
				}
			} else {
				this.textoerrorfechasreales = '';
			}

			const body = {
				idnotif: modalData.idnotif,
				fecentreal: modalData.fecentreal
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.guardarfechaentreal;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.crtlEntNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						var data = response.data;
						console.log(data);
						resolve(true);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkCrtlEntNotificacionMassive(modalData) {
			let ids = [];
			for (let i = 0; i < modalData.rows; i++) {
				const registrie = modalData[i];
				ids.push(registrie.idnotif);
			}

			const body = {
				ids: ids,
				date: modalData.fecentreal,
				isentrada: true
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.guardarfecharealMassive;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.crtlEntNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						var data = response.data;
						if (data) {
							resolve(true);
							this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						}
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkCrtlSalNotificacion(modalData) {
			const moment = require('moment');
			if (modalData.fecsalreal != null) {
				var mse = moment(modalData.fecentreal, 'YYYY-MM/DDTHH:mm:ss.000Z');
				var mss = moment(modalData.fecsalreal, 'YYYY-MM/DDTHH:mm:ss.000Z');
				if (mse.isAfter(mss)) {
					this.textoerrorfechasreales = this.$t(`action.notificacion.crtlSalNotificacion.error`);
					return;
				}
			} else {
				this.textoerrorfechasreales = '';
			}

			const body = {
				idnotif: modalData.idnotif,
				fecsalreal: modalData.fecsalreal
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.guardarfechasalreal;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.crtlEntNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						var data = response.data;
						console.log(data);
						resolve(true);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkCrtlSalNotificacionMassive(modalData) {
			let ids = [];
			for (let i = 0; i < modalData.rows; i++) {
				const registrie = modalData[i];
				ids.push(registrie.idnotif);
			}

			const body = {
				ids: ids,
				date: modalData.fecsalreal,
				isentrada: false
			};

			const urlController = this.$store.getters.getModelByName('notificacion').url.guardarfecharealMassive;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.notificacion.crtlSalNotificacion`);
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					urlController,
					body,
					(response) => {
						// Valid response, do code and close modal
						let data = response.data;
						if (data) {
							resolve(true);
							this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						}
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onShowCrtlEntNotificacion(modalData) {
			this.cargarFechasReales(modalData, true);
		},
		onShowCrtlSalNotificacion(modalData) {
			this.cargarFechasReales(modalData, false);
		},
		cargarFechasReales(modalData, ent) {
			const params = {
				idnotif: modalData.idnotif
			};
			const url = this.$store.getters.getModelByName('notificacion').url.get;
			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					modalData.fecentreal = response.data.fecentreal;
					modalData.fecsalreal = response.data.fecsalreal;
					if (ent && modalData.fecentreal == null) modalData.fecentreal = response.data.fechaactual;
					if (!ent && modalData.fecsalreal == null) modalData.fecsalreal = response.data.fechaactual;
					this.textoerrorfechasreales = '';
					this.refreshkey++;
				},
				(e) => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message);
				}
			);
		},
		onShowPdfAutorizacion() {
			this.showDialog = true;
		},
		verDocumento(modalData) {
			this.loading = true;
			const objectPk = { idnotif: modalData.idnotif };
			const urlController = this.$store.getters.getModelByName('notificacion').url.getPDFAutorizacion;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.getPDFAutorizacion`);

			this.$puiRequests.downloadFileRequest(
				'get',
				urlController,
				objectPk,
				(response) => {
					var blob = new Blob([response.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.loading = false;
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
					this.loading = false;
				}
			);
		},
		firmarDocumento(modalData) {
			this.loading = true;
			const objectPk = { idnotif: modalData.idnotif };
			this.pk = objectPk;
			const urlgetPdfFirm = this.$store.getters.getModelByName('notificacion').url.getPDFAutorizacionFirmada;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.getPDFAutorizacion`);
			this.$puiRequests.getRequest(
				urlgetPdfFirm,
				objectPk,
				(response) => {
					var params =
						'signaturePage=-1\nsignaturePositionOnPageLowerLeftX= 250\nsignaturePositionOnPageLowerLeftY= 250\nsignaturePositionOnPageUpperRightX= 470\nsignaturePositionOnPageUpperRightY= 200';

					AutoScript.cargarAppAfirma();
					AutoScript.sign(response.data, 'SHA512withRSA', 'Adobe PDF', params, this.showSignResultCallback, this.errorSignCallBack);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('action.notificacion.pdfAutorizacion.message.error');
					this.$puiNotify.error(message, title);
				}
			);
		},
		errorSignCallBack(type, message) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			this.$puiNotify.error(message);
			this.showDialog = false;
			this.loading = false;
		},
		showSignResultCallback(signatureB64) {
			this.downloadFile(signatureB64);
		},
		downloadFile(response) {
			const linkSource = 'data:application/pdf;base64,' + response;
			const downloadLink = document.createElement('a');
			downloadLink.href = linkSource;
			downloadLink.download = 'AutMMPP_' + this.pk.idnotif + '_Signed';
			downloadLink.click();
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			var message = this.$puiI18n.t('action.notificacion.pdfAutorizacion.message.download.success');
			this.$puiNotify.success(message);
			this.showDialog = false;
			this.loading = false;
		}
		//SUBIR PDF FIRMADO A DOCUMENTOS ADJUNTOS
		/* uploadFile(signatureB64) {
			console.log(signatureB64);
			const data = { pk: this.pk, encoded: signatureB64 };
			const urlUpload = this.$store.getters.getModelByName('notificacion').url.upload;

			return new Promise(resolve => {
				this.$puiRequests.postRequest(
					urlUpload,
					data,
					response2 => {
						// Valid response, do code and close modal
						if (response2.status === 200) {
							resolve(true);
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
							var message = this.$puiI18n.t('action.notificacion.pdfAutorizacion.message.success');
							this.$puiNotify.success(message);
							this.showDialog = false;
						}
					},
					e => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response2 && e.response2.data) {
							message = e.response2.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message);
						resolve(false);
					},
					null,
					this.pk
				);
			});
		} */
	},
	computed: {
		okTextAceptarNotificacion() {
			return this.erroresvalidacion ? this.$t('notificacion.modal.aceptar.cerrar') : this.$t('pui9.accept');
		},
		widthDialogAceptarNotificacion() {
			return this.erroresvalidacion ? '700' : 'unset';
		},
		okTextCancelarNotificacion() {
			return this.$t('pui9.accept');
		},
		okTextReemplazarNotificacion() {
			return this.$t('pui9.accept');
		},
		okTextCrtlEntNotificacion() {
			return this.$t('pui9.accept');
		},
		okTextCrtlSalNotificacion() {
			return this.$t('pui9.accept');
		},
		okTextFirmarPdf() {
			return this.$t('action.notificacion.pdfAutorizacion.firmar');
		},
		verPdf() {
			return this.$t('action.notificacion.pdfAutorizacion.verPdf');
		}
	},
	watch: {
		'internalModel.fecentreal': {
			immediate: true,
			handler() {
				this.textoerrorfechasreales = '';
			}
		},
		'internalModel.fecsalreal': {
			immediate: true,
			handler() {
				this.textoerrorfechasreales = '';
			}
		}
	}
};
</script>
