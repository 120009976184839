const resolucionAction = {
	id: 'idResolucionAction',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.notificacion.resolucionAction',
	functionality: null, // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/*registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		const pkStr = JSON.stringify(objectPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);

		row.headerPk = objectPk;
		row.isAction = true;
		//this.$puiEvents.$emit('pui-modalDialogForm-notificacionResolucionAction-' + model.name + '-show', row);
		const modelnotres = this.$store.getters.getModelByName('resolucion');
		this.$set(modelnotres, 'idestadonotificacion', row.idestado);

		const params = {
			router: this.$router,
			url: `notificacionres/read/${pk64}/`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

const getPDFNotificacion = {
	id: 'getPDFNotificacion',
	selectionType: 'single',
	label: 'action.getPDFNotificacion',
	functionality: 'READ_PDFNOTIFICACION',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		const urlController = this.$store.getters.getModelByName('notificacion').url.getPDFNotificacion;
		const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.getPDFNotificacion`);

		this.$puiRequests.downloadFileRequest(
			'get',
			urlController,
			objectPk,
			(response) => {
				const link = document.createElement('a');
				// eslint-disable-next-line no-undef
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
				if (fileNameMatch.length === 2) {
					link.download = fileNameMatch[1];
				}
				link.click();
				link.remove();
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				var message = this.$puiI18n.t('puiaction.notifySuccess');
				this.$puiNotify.success(message, title);
			},
			() => {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				var message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title);
			}
		);
	}
};

const getPDFAutorizacion = {
	id: 'getPDFAutorizacion',
	selectionType: 'single',
	label: 'action.getPDFAutorizacion',
	functionality: 'READ_PDFAUTORIZACION',
	checkAvailability: function (row) {
		return row[0].idestado != 5 && row[0].idestado != 6;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		var store = this.$store;
		var puiNotify = this.$puiNotify;
		var puiRequest = this.$puiRequests;
		var pui18n = this.$puiI18n;
		var puiEvents = this.$puiEvents;

		this.$puiRequests.getRequest(
			'/puivariable/getVariable/PDF_AUTORIZACION_FIRMADO',
			null,
			(response) => {
				actionPdf(response.data, store, puiNotify, puiRequest, pui18n, puiEvents);
			},
			(error) => {
				this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
			}
		);

		var actionPdf = function (pdfFirma, store, puiNotify, puiRequest, pui18n, puiEvents) {
			if (pdfFirma == '1') {
				row.isAction = true;
				puiEvents.$emit('pui-modalDialogForm-firmarPdfNotificacion-' + model.name + '-show', row);
			} else {
				const urlController = store.getters.getModelByName('notificacion').url.getPDFAutorizacion;
				const title = pui18n.t('puiaction.notifyTitle') + ' > ' + pui18n.t(`action.getPDFAutorizacion`);

				puiRequest.downloadFileRequest(
					'get',
					urlController,
					objectPk,
					(response) => {
						const link = document.createElement('a');
						// eslint-disable-next-line no-undef
						link.href = window.URL.createObjectURL(new Blob([response.data]));
						const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);
						if (fileNameMatch.length === 2) {
							link.download = fileNameMatch[1];
						}
						link.click();
						link.remove();
						puiEvents.$emit('onPui-action-running-ended-' + model.name);
						var message = pui18n.t('puiaction.notifySuccess');
						puiNotify.success(message, title);
					},
					() => {
						puiEvents.$emit('onPui-action-running-ended-' + model.name);
						var message = pui18n.t('puiaction.notifyError');
						puiNotify.error(message, title);
					}
				);
			}
		};
	}
};

const deleteNotificacion = {
	id: 'deleteNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.deleteNotificacion',
	functionality: 'WRITE_NOTIFICACION',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado (borrador o aceptada) y ha sido creada manualmente
		if (row[0] && row[0].manual == 'S' && (row[0].idestado == 1 || row[0].idestado == 2)) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-deleteNotificacion-' + model.name + '-show', row);
	}
};

const aceptarNotificacion = {
	id: 'aceptarNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.aceptarNotificacion',
	functionality: 'ACCEPT_NOTIFICACION',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado borrador y ha sido creada manualmente
		if (row[0] && row[0].manual == 'S' && row[0].idestado == 1) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-aceptarNotificacion-' + model.name + '-show', row);
	}
};

const cancelarNotificacion = {
	id: 'cancelarNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.cancelarNotificacion',
	functionality: 'CANCEL_NOTIFICACION',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado borrador y ha sido creada manualmente
		if (row[0] && row[0].manual == 'S' && (row[0].idestado == 2 || row[0].idestado == 3)) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-cancelarNotificacion-' + model.name + '-show', row);
	}
};

const reemplazarNotificacion = {
	id: 'reemplazarNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.reemplazarNotificacion',
	functionality: 'REPLACE_NOTIFICACION',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado borrador y ha sido creada manualmente
		if (row[0] && row[0].manual == 'S' && (row[0].idestado == 2 || row[0].idestado == 3)) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-reemplazarNotificacion-' + model.name + '-show', row);
	}
};
const crtlEntNotificacion = {
	id: 'crtlEntNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.crtlEntNotificacion',
	functionality: 'WRITE_FECENTSALREAL',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado autorizada
		if (row[0] && row[0].idtipoman == 1) {
			return false;
		}
		if (row[0] && row[0].idestado == 3) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-crtlEntNotificacion-' + model.name + '-show', row);
	}
};

// Registrar Entrada Mercancía Masiva
const crtlEntNotificacionMassive = {
	id: 'crtlEntNotificacionMassive',
	selectionType: 'multiple',
	label: 'action.notificacion.crtlEntNotificacionMassive',
	functionality: 'WRITE_FECENTSALREAL_MASSIVE',
	checkAvailability: function (row) {
		return row.idtipoman != 1 && row.idestado == 3;

		//solo debe de estar permitida en estado autorizada
		/*if (row[0] && row[0].idtipoman == 1) {
			return false;
		}
		if (row[0] && row[0].idestado == 3) {
			return true;
		}
		return false;*/
	},
	runAction: function (action, model, registries) {
		let rows = registries;
		console.log(rows);
		rows.isAction = true;
		rows.rows = rows.length;
		this.$puiEvents.$emit('pui-modalDialogForm-crtlEntNotificacionMassive-' + model.name + '-show', rows);
	}
};

const crtlSalNotificacion = {
	id: 'crtlSalNotificacion',
	selectionType: 'single',
	label: 'action.notificacion.crtlSalNotificacion',
	functionality: 'WRITE_FECENTSALREAL',
	checkAvailability: function (row) {
		//solo debe de estar permitida en estado autorizada
		if (row[0] && row[0].idtipoman == 2) {
			return false;
		}
		if (row[0] && row[0].idestado == 3) {
			return true;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-crtlSalNotificacion-' + model.name + '-show', row);
	}
};

// Registrar Salida Mercancía Masiva
const crtlSalNotificacionMassive = {
	id: 'crtlSalNotificacionMassive',
	selectionType: 'multiple',
	label: 'action.notificacion.crtlSalNotificacionMassive',
	functionality: 'WRITE_FECENTSALREAL_MASSIVE',
	checkAvailability: function (row) {
		return row.idtipoman != 2 && row.idestado == 3;
		//solo debe de estar permitida en estado autorizada
		/*if (row && row.idtipoman == 2) {
			return false;
		}
		if (row && row.idestado == 3) {
			return true;
		}
		return false;*/
	},
	runAction: function (action, model, registries) {
		let rows = registries;
		rows.isAction = true;
		rows.rows = rows.length;
		this.$puiEvents.$emit('pui-modalDialogForm-crtlSalNotificacionMassive-' + model.name + '-show', rows);
	}
};

export default {
	gridactions: [
		aceptarNotificacion,
		deleteNotificacion,
		cancelarNotificacion,
		getPDFAutorizacion,
		getPDFNotificacion,
		reemplazarNotificacion,
		crtlEntNotificacion,
		crtlEntNotificacionMassive,
		crtlSalNotificacion,
		crtlSalNotificacionMassive,
		resolucionAction
	],
	formactions: [
		aceptarNotificacion,
		deleteNotificacion,
		cancelarNotificacion,
		getPDFAutorizacion,
		getPDFNotificacion,
		reemplazarNotificacion,
		crtlEntNotificacion,
		crtlSalNotificacion,
		resolucionAction
	]
};
